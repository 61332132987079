// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/images/author-placeholder.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".kmHe-{display:flex}.kmHe- ._8UOXh{width:48px;height:48px;flex:0 0 auto;margin-right:16px;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");border-radius:100%}.kmHe- ._8UOXh img{background-color:#e2e2e2;color:#e2e2e2}._43QGD{margin:3px 0 2px;color:#2d2d2d;font-size:16px;font-weight:700;line-height:24px;transition:color .2s}a.kmHe-:active ._43QGD{-webkit-tap-highlight-color:rgba(0,0,0,0);color:rgba(45,45,45,.8)}@media(any-hover:hover){a.kmHe-:hover ._43QGD{color:rgba(45,45,45,.8)}}.chc6l{margin:0;color:#767676;font-size:13px;line-height:16px}[data-a11y] ._43QGD{margin:0 0 4px;font-size:18px;font-weight:600}[data-a11y] .chc6l{font-size:14px;line-height:20px}[data-a11y~=hidden-images] .kmHe- ._8UOXh{display:none}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"person": "kmHe-",
	"image": "_8UOXh",
	"name": "_43QGD",
	"position": "chc6l"
};
module.exports = ___CSS_LOADER_EXPORT___;
