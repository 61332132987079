import { computed, defineComponent, useCssModule } from '@nuxtjs/composition-api';
export default defineComponent({
    name: 'Person',
    props: {
        person: {
            required: true,
            type: Object,
        },
    },
    setup(props) {
        /**
         * CSS Modules.
         */
        const css = useCssModule();
        /**
         * Is Link?
         */
        const isLink = computed(() => !!props.person.url);
        /**
         * Current root attributes.
         */
        const currentAttrs = computed(() => {
            if (!isLink.value) {
                return;
            }
            return {
                to: props.person.url,
            };
        });
        /**
         * Current root component.
         */
        const currentComponent = computed(() => {
            return isLink.value ? 'NLink' : 'a';
        });
        return {
            css,
            currentAttrs,
            currentComponent,
            isLink,
        };
    },
});
