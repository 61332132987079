import { computed, defineComponent, useCssModule } from '@nuxtjs/composition-api';
import { getArticleInfo } from '~/utils';
export default defineComponent({
    name: 'CardOpinion',
    props: {
        data: {
            required: true,
            type: Object,
        },
        size: {
            required: true,
            type: Object,
        },
    },
    setup(props, { root }) {
        /**
         * CSS Modules.
         */
        const css = useCssModule();
        const author = computed(() => {
            const { authors = [] } = props.data;
            const [firstAuthor] = authors;
            if (!firstAuthor) {
                return null;
            }
            return {
                image: firstAuthor.photo,
                name: firstAuthor.title,
                position: firstAuthor.subtitle,
                url: `/${firstAuthor.url_alias}`,
            };
        });
        /**
         * Article info.
         */
        const articleInfo = getArticleInfo.call(root, props.data);
        return {
            articleInfo,
            css,
            author,
        };
    },
});
