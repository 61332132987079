// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".VI\\+X\\+{display:flex;flex-direction:column;background:#fff;border-radius:8px;box-shadow:0 0 16px 0 rgba(0,0,0,.08);cursor:pointer}@media screen and (min-width:768px){.VI\\+X\\+{border:1px solid #e2e2e2;box-shadow:none;transition:box-shadow .4s}.VI\\+X\\+:active{-webkit-tap-highlight-color:rgba(0,0,0,0);box-shadow:0 2px 18px rgba(0,0,0,.08)}}@media screen and (min-width:768px)and (any-hover:hover){.VI\\+X\\+:hover{box-shadow:0 2px 18px rgba(0,0,0,.08)}}.gRoND{flex:1;padding:0 16px;margin:0;color:#2d2d2d;transition:color .2s}.VI\\+X\\+:active .gRoND{-webkit-tap-highlight-color:rgba(0,0,0,0);color:rgba(45,45,45,.8)}@media(any-hover:hover){.VI\\+X\\+:hover .gRoND{color:rgba(45,45,45,.8)}}.hIT8f{font-size:16px;line-height:22px}@media screen and (min-width:768px){.hIT8f{margin:0;font-family:\"Merriweather\",serif;font-size:18px;font-weight:700;line-height:24px;-webkit-line-clamp:6;-webkit-box-orient:vertical;display:block;display:-webkit-box;overflow:hidden;max-height:144px}}.LTAgc{padding:0 16px 24px;margin-top:40px}@media screen and (min-width:768px){.LTAgc{margin-top:0}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "VI+X+",
	"path": "gRoND",
	"title": "hIT8f",
	"author": "LTAgc"
};
module.exports = ___CSS_LOADER_EXPORT___;
